body {
  margin: 0;
  font-size: 16px;
}

a {
  color: #7d5fb2;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.4s;
}

a:hover {
  color: #c29fff;
}

.text-center {
  text-align: center;
}

.top-bar {
  padding: 10px 15px;
  /* margin-bottom: 50px; */
   margin-bottom: 5px;
}
.top-bar::after {
  content: '';
  display: block;
  clear: both;
}

.top-bar-left {
  float: left;
  font-size: 1.5em;
}

.top-bar-right {
  float: right;
}

.top-bar a,
.nav a {
  margin: 0 8px;
}

.container {
  margin: 0 auto;
  text-align: center;
  width: 700px;
}

.card-heading {
  padding: 16px;
}

.field-line, .button-line {
  padding: 16px;
}

.error-message {
  padding: 0 16px;
  color: tomato;
}

.success-message {
  padding: 0 16px;
  color: green;
}
